import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import axios from "axios";
import Webcam from 'react-webcam'
import { ATTENDANCE_API } from "../../../constants/api";
import moment from "moment";
import deviceInfo from "../../../utills/deviceInfo";


const AddAttendance = () => {
    const toastRef = useRef(null);
    const [currentDate, setCurrentDate] = useState("");
    const [currentTime, setCurrentTime] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const attendanceOptions = [
        { label: "Present", value: "Present" },
        { label: "On The Way", value: "On The Way" },
        { label: "Day Off", value: "Day Off" },
        { label: "Absent", value: "Absent" },
        { label: "Permissions", value: "Permissions" },
        { label: "Comp Off", value: "Comp Off" },
        { label: "Sick Leave", value: "Sick Leave" },
        { label: "Optional Leave", value: "Optional Leave" },
    ];
    const [products, setProducts] = useState([]);
    const [attendanceStatus, setAttendanceStatus] = useState("Present");
    const [statusError, setStatusError] = useState(false);
    const [webCamView, setWebCamView] = useState(false)
    const [clickCamera, setClickCamera] = useState(false)
    const webcamRef = useRef(null);
    const showToast = (message) => {
        toastRef.current.clear();
        if (toastRef && toastRef.current) {
            toastRef.current.show({
                severity: "error",
                summary: "Error Message",
                detail: message,
            });
        }
    };
    const showSuccessToast = (message) => {
        toastRef.current.clear();
        if (toastRef && toastRef.current) {
            toastRef.current.show({
                severity: "success",
                summary: "Success Message",
                detail: message,
            });
        }
    };

    const deviceType = deviceInfo()
   
    useEffect(() => {
        fetchAttendanceData();
    }, []);

    const fetchAttendanceData = () => {
        const userDataString = localStorage.getItem("user");

        if (userDataString) {
            const userData = JSON.parse(userDataString);
            const userid = userData.data.id;

            axios
                .get(ATTENDANCE_API.ATTENDANCE_GET(userid))
                .then((response) => {
                    setProducts(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching user data:", error.message);
                });
        } else {
            console.error("User data not found in local storage");
        }
    };

    const fetchCurrentDate = () => {
        const currentDate = moment().format("YYYY-MM-DD");
        return products.some(item => {
            return item.date === currentDate
        });
    };

    const currentDateObject = fetchCurrentDate();

    const fetchProductsForCurrentDate = () => {
        const currentDate = moment().format("YYYY-MM-DD");
        return products.filter(item => item.date === currentDate);
    };

    const iSCurrentData = fetchProductsForCurrentDate()

    const getCurrentDateTime = () => {
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const seconds = now.getSeconds();
        const period = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
        setCurrentTime(
            `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${period}`
        );
        setCurrentDate(now.toISOString().split("T")[0]);
    };

    useEffect(() => {
        getCurrentDateTime();
        const intervalId = setInterval(getCurrentDateTime, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const handleStatusChangeUpdate = (e) => {
        const value = e.target.value;
        setAttendanceStatus(value);
        setStatusError(!value);
    };

    const handleSubmit = async () => {
        setClickCamera(true);

        if (isSubmitting) {
            return;
        }

        if (!attendanceStatus) {
            setStatusError(true);
            setClickCamera(false);
            return;
        } else {
            setStatusError(false);
        }

        setIsSubmitting(true);

        const capturePhoto = () => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    const imageSrc = webcamRef.current.getScreenshot({
                        quality: 1, width: 1800, height: 1880
                    });
                    resolve(imageSrc);
                }, 3000);
            });
        };

        try {
            let imageSrc;
            if (attendanceStatus === "Present") {
                imageSrc = await capturePhoto();
            }

            const userDataString = localStorage.getItem("user");
            if (!userDataString) {
                throw new Error("User data not found.");
            }

            const userData = JSON.parse(userDataString);
            const userid = userData.data.id;

            const formData = new FormData();
            formData.append('date', currentDate);
            formData.append('check_in', currentTime);
            formData.append('status', attendanceStatus);
            formData.append('userid', userid);
            formData.append('check_in_device', deviceType);

            if (imageSrc) {
                const blob = dataURItoBlob(imageSrc);
                formData.append('image', blob, 'image.jpeg');
            }

            await axios.post(ATTENDANCE_API.ATTENDANCE_ADD, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });

            fetchAttendanceData();
            showSuccessToast("Check-in successful");

        } catch (error) {
            console.error("Error:", error.message);
            const errorMessage = error.response?.data?.message || "An error occurred.";
            showToast(errorMessage);
        } finally {
            setIsSubmitting(false);
            setClickCamera(false);
        }
    };

    const handleConfirm = (rowData) => {
        setClickCamera(true);

        if (!rowData) {
            console.error("No row selected");
            showToast("No row selected.");
            setClickCamera(false);
            return;
        }

        const { id } = rowData;

        const capturePhoto = () => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    const imageSrc = webcamRef.current.getScreenshot({
                        quality: 1, width: 1800, height: 1880
                    });
                    resolve(imageSrc);
                }, 3000); // Adjust delay if needed
            });
        };

        capturePhoto().then((imageSrc) => {
            const formData = new FormData();
            formData.append('check_out', currentTime);
            formData.append('check_out_device', deviceType);
            if (imageSrc) {
                // Convert base64 to Blob
                const blob = dataURItoBlob(imageSrc);
                formData.append('image', blob, 'image.jpeg');
            }

            axios.put(ATTENDANCE_API.ATTENDANCE_CHECKOUT(id), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then((response) => {
                    fetchAttendanceData();
                    fetchProductsForCurrentDate();
                    showSuccessToast(`${response.data.message}`);
                })
                .catch((error) => {
                    console.error("Failed to check out:", error.message);
                    showToast("Failed to check out.");
                })
                .finally(() => {
                    setClickCamera(false);
                });

        }).catch((error) => {
            console.error("Failed to capture photo:", error.message);
            showToast("Failed to capture photo.");
            setClickCamera(false);
        });
    };

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    useEffect(() => {
        const filterData = products.filter((item) => {
            const filterDate = moment(item.date).format("DD-MM-YYYY");
            const currentDate = moment().format("DD-MM-YYYY");
            return currentDate === filterDate;
        });

        if (filterData.length == 1) {
            setWebCamView(true);
        }
    }, [products]);

    return (<>
        <Toast ref={toastRef} />
        {!currentDateObject ?
            <div className="card my-3" style={{ backgroundColor: '#e7e1f5', border: '2px solid #ac93e5' }}>
                <div className="flex align-items-center flex-wrap gap-3">
                    <div className="flex-1">
                        <label htmlFor="currentDate">Date</label>
                        <input id="currentDate" className="outline-none cursor-pointer" value={currentDate} readOnly />
                    </div>

                    <div className="flex-1">
                        <label htmlFor="currentTime">Time</label>
                        <input type="text" id="currentTime" className="outline-none cursor-pointer " value={currentTime} readOnly />
                    </div>

                    <div className="">
                        <label htmlFor="attendanceStatus">Status</label>
                        <div className="flex flex-wrap gap-1 lg:gap-2">
                            {attendanceOptions.map(option => (
                                <div key={option.value} className="flex align-items-center">
                                    <input
                                        type="radio"
                                        id={option.value}
                                        name="attendanceStatus"
                                        value={option.value}
                                        checked={attendanceStatus === option.value}
                                        onChange={(e) => {
                                            setAttendanceStatus(e.target.value);
                                            setStatusError(false);
                                        }}
                                        className={`${statusError ? "p-invalid" : ""} p-0 w-auto`}
                                    />
                                    <label htmlFor={option.value}
                                        style={{ whiteSpace: "nowrap" }} className=" font-normal mt-1">{option.label}</label>
                                </div>
                            ))}
                        </div>
                        {statusError && (
                            <small className="p-error ml-2">Status is required.</small>
                        )}
                    </div>

                    {attendanceStatus === "Present" && !webCamView &&
                        <div className="">
                            <label htmlFor="camera">Camera</label>
                            <div
                                style={{
                                    width: !clickCamera && "50px",
                                    height: !clickCamera && "50px",
                                }}
                            >
                                {clickCamera &&
                                    <Webcam
                                        audio={false}
                                        ref={webcamRef}
                                        screenshotFormat="image/jpeg"
                                        imageSmoothing={true}
                                        screenshotQuality={1}
                                        videoConstraints={{ facingMode: "user" }}
                                        disablePictureInPicture={false}
                                        style={{ width: '50px', height: '50px' }}
                                    />
                                }
                            </div>
                        </div>
                    }
                    <div className="align-self-center mt-4">
                        <Button
                            label="Check In"
                            icon="fi fi-rr-tap"
                            className="p-button p-2"
                            onClick={handleSubmit}
                        />
                    </div>
                </div>
            </div> : currentDateObject && iSCurrentData[0].check_out == null && iSCurrentData[0].status == "Present" &&
            <div className="card my-3 lg:col-6" style={{ backgroundColor: '#FED2D1', border: '2px solid #FC8380' }}>
                <div className="flex align-items-center flex-wrap gap-3">
                    <div className="">
                        <label htmlFor="currentDate">Date</label>
                        <input id="currentDate" style={{ maxWidth: '150px' }} className="outline-none cursor-pointer" value={currentDate} readOnly />
                    </div>

                    <div className="">
                        <label htmlFor="currentTime">Time</label>
                        <input type="text" id="currentTime" style={{ maxWidth: '150px' }} className="outline-none cursor-pointer " value={currentTime} readOnly />
                    </div>
                    {attendanceStatus === "Present" && !iSCurrentData[0].check_out &&
                        <div
                        >
                            <label htmlFor="camera">Camera</label>
                            <div className="" style={{
                                width: !clickCamera && "50px",
                                height: !clickCamera && "50px",
                            }}>
                                {clickCamera &&
                                    <div >
                                        <Webcam
                                            audio={false}
                                            ref={webcamRef}
                                            screenshotFormat="image/jpeg"
                                            imageSmoothing={true}
                                            screenshotQuality={1}
                                            videoConstraints={{ facingMode: "user" }}
                                            disablePictureInPicture={false}
                                            style={{ width: '50px', height: '50px', transform: 'scaleX(1)' }}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <div className="align-self-center mt-4">
                        <Button
                            label="Check Out"
                            icon="fi fi-rr-tap"
                            className="p-button p-2"
                            onClick={() => handleConfirm(iSCurrentData[0])}
                        />
                    </div>

                </div>
            </div>

        }
    </>

    )
}

export default AddAttendance
