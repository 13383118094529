export const BASE_URL = "https://khrms.kodukku.com/api";

export const AUTH_API = {
  PASSWORD_CHANGE: (userId) => `${BASE_URL}/auth/passwordchange/${userId}`,
  ROLE_CHANGE: (userId) => `${BASE_URL}/auth/rolechange/${userId}`,
  ALL_USER: `${BASE_URL}/auth/getalluser`,
}

export const ASSETS_IMAGES_API = {
  ASSETS_IMAGES: `${BASE_URL.split("/api")[0]}/assets/Images/`,
}
export const ASSETS_DOC_API = {
  ASSETS_DOC: `${BASE_URL.split("/api")[0]}/assets/OnlyPDF/`,
}

export const CATEGORY_API = {
  CATEGORY_FETCH: (empid) => `${BASE_URL}/staffcategories${empid}`,
  GET_ALL_CATEGORY: `${BASE_URL}/staffcategories`,
  ADD_CATEGORY: `${BASE_URL}/staffcategories`,
  EDIT_CATEGORY: (staffcategoryId) => `${BASE_URL}/staffcategories/${staffcategoryId}`,
  DELETE_CATEGORY: (staffcategoryId) => `${BASE_URL}/staffcategories/${staffcategoryId}`,
};

export const ASSETS_API = {
  GET_REQUEST_ALL: `${BASE_URL}/assets/request`,
  ASSETS_REQUEST_FETCH: (empid) => `${BASE_URL}/assets/request/${empid}`,
  ASSETS_REQUEST_ADD: `${BASE_URL}/assets/request`,
  ASSETS_REQUEST_UPDATE: (assetsRequestId) => `${BASE_URL}/assets/request/${assetsRequestId}`,
  ASSETS_REQUEST_UPDATE_STATUS: (assetsRequestId) => `${BASE_URL}/assets/request/status/${assetsRequestId}`,
  ASSETS_REQUEST_DELETE: (assetsRequestId) => `${BASE_URL}/assets/request/${assetsRequestId}`,
  ASSETS_FETCH: (empid) => `${BASE_URL}/assets/${empid}`,
  GET_ALL: `${BASE_URL}/assets`,
  ASSETS_ADD: `${BASE_URL}/assets`,
  ASSETS_UPDATE: (assetId) => `${BASE_URL}/assets/${assetId}`,
  ASSETS_DELETE: (assetId) => `${BASE_URL}/assets/${assetId}`,
};

export const LETTER_API = {
  LETTER_FETCH: (empid) => `${BASE_URL}/letter/${empid}`,
  GET_ALL: `${BASE_URL}/letter`,
  LETTER_ADD: `${BASE_URL}/letter`,
  LETTER_UPDATE: (letterId) => `${BASE_URL}/letter/${letterId}`,
  LETTER_DELETE: (letterId) => `${BASE_URL}/letter/${letterId}`,
  LETTER_DOWNLOAD: (letterId) => `${BASE_URL}/letter/download/${letterId}`,
};

export const MEMO_API = {
  MEMO_FETCH: (empid) => `${BASE_URL}/memo/${empid}`,
  GET_ALL: `${BASE_URL}/memo`,
  MEMO_ADD: `${BASE_URL}/memo`,
  MEMO_UPDATE: (memoId) => `${BASE_URL}/memo/${memoId}`,
  MEMO_DELETE: (memoId) => `${BASE_URL}/memo/${memoId}`,
  MEMO_DOWNLOAD: (memoId) => `${BASE_URL}/memo/download/${memoId}`,
};

export const PERSONAL_DOCUMENT_API = {
  DOCUMENT_FETCH: (empid) => `${BASE_URL}/personal_Document/${empid}`,
  GET_ALL: `${BASE_URL}/personal_Document`,
  DOCUMENT_ADD: `${BASE_URL}/personal_Document`,
  DOCUMENT_UPDATE: (personal_DocumentId) => `${BASE_URL}/personal_Document/${personal_DocumentId}`,
  DOCUMENT_DELETE: (personal_DocumentId) => `${BASE_URL}/personal_Document/${personal_DocumentId}`,
  DOCUMENT_DOWNLOAD: (personal_DocumentId) => `${BASE_URL}/personal_Document/download/${personal_DocumentId}`,
};

export const EMPLOYEE_API = {
  GET_ALL: `${BASE_URL}/employee`, //get
  All_USERS: `${BASE_URL}/employee/allEmployees`, //get
  ACTIVE_INACTIVE_USERS: `${BASE_URL}/employee/active/inactive/users`, //get
  INACTIVE_USERS: `${BASE_URL}/employee/inactiveEmployees`, //get
  EDIT_USER: (empId) => `${BASE_URL}/employee/edituser/${empId}`, //put
  DELETE_USER: (empId) => `${BASE_URL}/employee/deleteuser/${empId}`, //delete
  ADD_USER: `${BASE_URL}/employee/adduser`, //post
  USER_PROFILE: (userId) => `${BASE_URL}/employee/${userId}`, //get
  GET_USER_ID: `${BASE_URL}/employee/employeeid`,
  GET_REPORTING_MANAGER: (managerId) =>
    `${BASE_URL}/employee/reportingmanager/${managerId}`, //get
  GET_ALL_REPORTING_MANAGER: `${BASE_URL}/employee/reportingmanager`, //get
  GET_PROFILE: `https://ktest.kodukku.com/api/getprofile`, //post
  GET_PROFILE_BYID: `https://ktest.kodukku.com/api/getprofilebyid`, //post
  GET_PROFILE_PDF: (id) => `https://ktest.kodukku.com/api/user-details/${id}`, //get
  TO_ACTIVE_USER: (empId) => `${BASE_URL}/employee/activeuser/${empId}`, //put
  GET_USER_DETAILS_KID: `https://ktest.kodukku.com/api/userDetails`,
  REPORTING_MANAGER_BYDEPARTMNET: `${BASE_URL}/employee/reporting/manager`, //get
  REPORTING_MANAGER_BYID: (reporting_manager_id) => `${BASE_URL}/employee/reportingmanager/${reporting_manager_id}`, //get
  GET_BIRTHDAY: `${BASE_URL}/employee/birthday/all`,
  SEEN_BIRTHDAY: `${BASE_URL}/employee/birthdayseen`,
};

export const LEAVE_API = {
  LEAVE_FETCH: (userId) => `${BASE_URL}/leave/${userId}`,
  LEAVE_ADD: `${BASE_URL}/leave`,
  LEAVE_UPDATE: (leaveRequestId) => `${BASE_URL}/leave/${leaveRequestId}`,
  LEAVE_REASON: (leaveRequestId) =>
    `${BASE_URL}/leave/leaverequest/reason/${leaveRequestId}`,
  LEAVE_DELETE: (leaveRequestId) => `${BASE_URL}/leave/${leaveRequestId}`,
  LEAVE_PAID_APPROVED: (leaveRequestId) => `${BASE_URL}/leave/paidleave/approved/${leaveRequestId}`,
  LEAVE_PAID_REJECTED: (leaveRequestId) => `${BASE_URL}/leave/paidleave/rejected/${leaveRequestId}`,
};

export const ADMIN_LEAVE_API = {
  GET_ALL_LEAVE: `${BASE_URL}/leave`,
  EDIT_LEAVETYPE: (leaveRequestId) =>
    `${BASE_URL}/leave/leavetype/edit/${leaveRequestId}`,
  UPDATE_REJECTION_REASON: (leaveRequestId) =>
    `${BASE_URL}/leave/rejection/reason/user/${leaveRequestId}`,
  LEAVE_APPROVAL: (leaveRequestId) =>
    `${BASE_URL}/leave/leaveApproval/${leaveRequestId}`,
  LEAVE_BALANCE: (empId) => `${BASE_URL}/leave/leavebalance/${empId}`,
  USER_LEAVEDATA: (leaveRequestId) =>
    `${BASE_URL}/leave/user/leaveData/${leaveRequestId}`,
  GET_LEAVE_DOCUMENT: (leaveRequestId) =>
    `${BASE_URL}/leave/doc/${leaveRequestId}`,
  DOWNLOAD_LEAVE_FILE: (folder, filename) =>
    `${BASE_URL}/leave/download/${folder}/${filename}`,
};

export const PAY_HEAD_API = {
  GET_ALL_PAYHEAD: `${BASE_URL}/payhead`,
  EDIT_PAYHEAD: (payhead_id) => `${BASE_URL}/payhead/${payhead_id}`,
  DELETE_PAYHEAD: (payhead_id) => `${BASE_URL}/payhead/${payhead_id}`,
};

export const PAYROLL_API = {
  GET_ALL_PAYROLL: `${BASE_URL}/payroll`,
  GET_ALL_PAYROLL_EMP: (payroll_id) => `${BASE_URL}/payroll/user/${payroll_id}`,
  ADD_PAYROLL: `${BASE_URL}/payroll`,
  ADD_MULTIPLE_PAYROLL: `${BASE_URL}/payroll/addpayroll`,
  GET_PARTICULAR_PAYROLL: (payroll_id) => `${BASE_URL}/payroll/${payroll_id}`,
  EDIT_PAYROLL: (payroll_id) => `${BASE_URL}/payroll/${payroll_id}`,
  DELETE_PAYROLL: (payroll_id) => `${BASE_URL}/payroll/${payroll_id}`,
  GET_PAYROLL_BY_YEAR_AND_MONTH: (id, year, month) =>
    `${BASE_URL}/payroll/byYearAndMonth/${id}/${year}/${month}`,
  DELETE_PAYROLL_BY_FROM_DATE_AND_TO_DATE: (fromDate, toDate) =>
    `${BASE_URL}/payroll/${fromDate}/${toDate}`,
};

export const TASK_API = {
  GET_CREATER_TASK: (creater) => `${BASE_URL}/task/creater/${creater}`,
  GET_ALL_TASK: `${BASE_URL}/task`,
  GET_PARTICULAR_TASK: (taskId) => `${BASE_URL}/task/${taskId}`,
  ADD_TASK: `${BASE_URL}/task`,
  EDIT_TASK: (taskId) => `${BASE_URL}/task/${taskId}`,
  DELETE_TASK: (taskId) => `${BASE_URL}/task/${taskId}`,
  GET_USER_TASK: (id) => `${BASE_URL}/task/user/teammember/${id}`,
  TASK_APPROVAL_ADMIN_APPROVAL: (taskId) =>
    `${BASE_URL}/task/admintaskapproval/${taskId}`,
  TASK_APPROVAL_ADMIN_REJECTION: (taskId) =>
    `${BASE_URL}/task/admintask/rejection/${taskId}`,
  TASK_APPROVAL_CREATOR: (taskId) => `${BASE_URL}/task/taskapproval/${taskId}`,
  TASK_PARTICIPANTS_GET: (participants) =>
    `${BASE_URL}/task/meeting/participants/user/${participants}`,
  GET_TASK_DOCUMENT: (taskId) => `${BASE_URL}/task/doc/${taskId}`,
  DOWNLOAD_FILE: (folder, filename) =>
    `${BASE_URL}/task/download/${folder}/${filename}`,
};


export const TASK_QUERY_API = {
  GET_TASKQUERY: (taskId) => `${BASE_URL}/taskdoubt/${taskId}`,
  TASK_QUERY_ADD: `${BASE_URL}/taskdoubt`,
  TASK_PROGRESS_ADD: `${BASE_URL}/taskdoubt/taskprogress`,
  TASK_FILE_ADD: `${BASE_URL}/taskdoubt/employeefile/upload`,
  EDIT_FILE_DETAILS: (taskId) =>
    `${BASE_URL}/taskdoubt/employeefile/upload/${taskId}`,
  GET_TASK_DOCUMENT_USER: (taskId) =>
    `${BASE_URL}/taskdoubt/employeefile/doc/${taskId}`,
  DOWNLOAD_FILE_USER: (folder, filename) =>
    `${BASE_URL}/taskdoubt/employeefile/upload/download/${folder}/${filename}`,
};

export const TASK_File_UPLOAD = {
  TASK_FILE_ADD: `${BASE_URL}/taskdocument/adminfile/upload`,
  EDIT_FILE_DETAILS: (taskId) =>
    `${BASE_URL}/taskdocument/adminfile/upload/${taskId}`,
  GET_TASK_DOCUMENT_USER: (taskId) =>
    `${BASE_URL}/taskdocument/adminfile/doc/${taskId}`,
  DOWNLOAD_FILE_USER: (folder, filename) =>
    `${BASE_URL}/taskdocument/adminfile/upload/download/${folder}/${filename}`,
};

export const PROJECT_API = {
  GET_ALL_PROJECTS: `${BASE_URL}/project`,
  ADD_PROJECT: `${BASE_URL}/project`,
  EDIT_PROJECT: (project_id) => `${BASE_URL}/project/${project_id}`,
  DELETE_PROJECT: (project_id) => `${BASE_URL}/project/${project_id}`,
  GET_EMPLOYEE_PROJECT: (id) => `${BASE_URL}/project/team-members/${id}`,
};

export const DEPARTMENT_API = {
  DEPARTMENT_FETCH: `${BASE_URL}/department`,
  DEPARTMENT_ADD: `${BASE_URL}/department`,
  DEPARTMENT_UPDATE: (department_id) =>
    `${BASE_URL}/department/${department_id}`,
  DEPARTMENT_DELETE: (department_id) =>
    `${BASE_URL}/department/${department_id}`,
};

export const APPOINTMENTS_API = {
  APPOINTMENTS_FETCH: `${BASE_URL}/appointments`,
  APPOINTMENTS_ADD: `${BASE_URL}/appointments`,
  APPOINTMENTS_UPDATE: (appointment_id) =>
    `${BASE_URL}/appointments/${appointment_id}`,
  APPOINTMENTS_RESHEDULE: (appointment_id) =>
    `${BASE_URL}/appointments/reshedule/${appointment_id}`,
  APPOINTMENTS_DELETE: (appointment_id) =>
    `${BASE_URL}/appointments/${appointment_id}`,
  APPOINTMENTS_MEMBER: (employee_ids) =>
    `${BASE_URL}/appointments/appointment_member/${employee_ids}`,
  APPOINTMENTS_DEACTIVATE: (appointment_id) =>
    `${BASE_URL}/appointments/${appointment_id}/deactivate`,
  APPOINTMENTS_ABSENCES: (appointment_id) =>
    `${BASE_URL}/appointments/${appointment_id}/absences`,
};

export const ATTENDANCE_API = {
  ATTENDANCE_ADD: `${BASE_URL}/attendance`,
  ATTENDANCE_TODAY: `${BASE_URL}/attendance`,
  ATTENDANCE_TODAY_PRESENT: `${BASE_URL}/attendance/getPresent`,
  ATTENDANCE_GET: (userid) => `${BASE_URL}/attendance/${userid}`,
  ATTENDANCE_GET_MONTH: (userid, month, year) =>
    `${BASE_URL}/attendance/month/${userid}?month=${month}&year=${year}`,
  ATTENDANCE_UPDATE: (id) => `${BASE_URL}/attendance/${id}`,
  ATTENDANCE_UPDATE_ADMIN: (id) => `${BASE_URL}/attendance/update/admin/${id}`,
  ATTENDANCE_CHECKOUT: (id) => `${BASE_URL}/attendance/check_out/${id}`,
  ATTENDANCE_MONTH_TOTAL: (id, month, year) =>
    `${BASE_URL}/attendance/${id}/${month}/${year}`,
  ATTENDANCE_PAYROLL_GET: (id) =>
    `${BASE_URL}/attendance/fromdate/todate/payroll/${id}`,
  ATTENDANCE_DELETE: (id) => `${BASE_URL}/attendance/${id}`,
};

export const HOLIDAYS_API = {
  HOLIDAYS_ADD: `${BASE_URL}/holidays`,
  HOLIDAYS_GET: `${BASE_URL}/holidays`,
  HOLIDAYS_ALL: `${BASE_URL}/holidays/allHolidays`,
  HOLIDAYS_UPDATE: (id) => `${BASE_URL}/holidays/${id}`,
  HOLIDAYS_DELETE: (id) => `${BASE_URL}/holidays/${id}`,
};

export const PERMISSION_API = {
  PERMISSION_ADD: `${BASE_URL}/permissions`,
  PERMISSION_GET: `${BASE_URL}/permissions`,
  PERMISSION_GET_BYID: (userid) => `${BASE_URL}/permissions/${userid}`,
  PERMISSION_ALL: `${BASE_URL}/permissions/allpermissions`,
  PERMISSION_UPDATE: (id) => `${BASE_URL}/permissions/${id}`,
  PERMISSION_ADMIN_UPDATE: (id) => `${BASE_URL}/permissions/admin/edit/${id}`,
  PERMISSION_DELETE: (id) => `${BASE_URL}/permissions/${id}`,
  PERMISSION_APPROVAL: (id) =>
    `${BASE_URL}/permissions/permissionApproval/${id}`,
  PERMISSION_BALANCE: (userid) =>
    `${BASE_URL}/permissions/permissionbalance/${userid}`,
};

export const COMP_OFF_API = {
  COMP_OFF_GET: (userId) => `${BASE_URL}/compoff/${userId}`, //get
  COMP_OFF_ADD: `${BASE_URL}/compoff`, //post
  COMP_OFF_UPDATE: (id) => `${BASE_URL}/compoff/${id}`, //put
  COMP_OFF_DELETE: (id) => `${BASE_URL}/compoff/${id}`, //delete
};
export const COMP_OFF_ADMIN_API = {
  COMPOFF_ALL_GET: `${BASE_URL}/compoff`, //get
  COMP_OFF_ADD: `${BASE_URL}/compoff`, //post
  COMP_OFF_UPDATE: (id) => `${BASE_URL}/compoff/${id}`, //put
  COMP_OFF_APPROVAL: (id) => `${BASE_URL}/compoff/update-status/${id}`, //patch
};

export const TIME_SHEET_API = {
  TIME_SHEET_DATA: `${BASE_URL}/timesheet`, //get
  TIME_SHEET_GET: (userid) => `${BASE_URL}/timesheet/${userid}`, //get
  TIME_SHEET_ADD: `${BASE_URL}/timesheet`, //post
  TIME_SHEET_UPDATE: (id) => `${BASE_URL}/timesheet/${id}`, //put
  TIME_SHEET_DELETE: (id) => `${BASE_URL}/timesheet/${id}`, //delete
  TIME_SHEET_ALLSTATUS_UPDATE: (date, userid) =>
    `${BASE_URL}/timesheet/status/user/${date}/${userid}`, //put
  TIME_SHEET_STATUS_UPDATE: (id) => `${BASE_URL}/timesheet/status/${id}`, //patch
};
