import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import {
  EMPLOYEE_API,
  PAY_HEAD_API,
  PAYROLL_API,
  ATTENDANCE_API,
} from "../../constants/api";
import axios from "axios";
import AllPayRoll from "./EmployeeAddAllPayroll";
import { Toast } from "primereact/toast";
import EmployeePayrollDelete from "./EmployeePayrollDelete";
import EmployeeAddPayroll from "./EmployeeAddPayroll";
import { FaDownload, FaFileExcel, FaFilePdf } from "react-icons/fa";
import { Menu } from "primereact/menu";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import {
  setFirst,
  setPageRows,
  selectMonthEmp,
  selectYearEmp,
  clearStore,
} from "../../service/paginator";
import DeletePayroll from "./DeletePayroll";

export default function EmpPayslip() {
  const [payslips, setPayslips] = useState([]);
  const [payroll, setPayroll] = useState([]);
  const [selectedPayslips, setSelectedPayslips] = useState(null);
  const [displayAddPayHeadsDialog, setDisplayAddPayHeadsDialog] =
    useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [earnings, setEarnings] = useState([]);
  const [deductions, setDeductions] = useState([]);
  const [newEarning, setNewEarning] = useState({
    payhead_id: "",
    payhead_name: "",
    payhead_amount: "",
  });
  const [newDeduction, setNewDeduction] = useState({
    payhead_id: "",
    payhead_name: "",
    payhead_amount: "",
  });
  const [earningOptions, setEarningOptions] = useState([]);
  const [deductionOptions, setDeductionOptions] = useState([]);
  const [earningIds, setEarningIds] = useState({});
  const [deductionIds, setDeductionIds] = useState({});

  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalDeductions, setTotalDeductions] = useState(0);
  const [netPay, setNetPay] = useState(0);
  const [monthFilterOptions, setMonthFilterOptions] = useState(null);
  const [yearFilterOptions, setYearFilterOptions] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [selectedPayrollToDelete, setSelectedPayrollToDelete] = useState(null);
  const [selectedEmployeeDetails, setSelectedEmployeeDetails] = useState({
    name: "",
    department: "",
    position: "",
    empid: "",
    salary: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [searchMonth, setSearchMonth] = useState("");
  const [searchYear, setSearchYear] = useState("");
  const [filteredPayroll, setFilteredPayroll] = useState([]);
  const [selectedPayrollData, setSelectedPayrollData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [totalNetPay, setTotalNetPay] = useState(0);
  const toast = useRef(null);
  const menuRef = useRef(null);
  const [numSundays, setNumSundays] = useState(0);
  const [totalDaysPresent, setTotalDaysPresent] = useState(0);
  const [attendanceDetails, setAttendanceDetails] = useState({
    empid: null,
    from_date: null,
    to_date: null,
  });

  const dispatch = useDispatch();
  const first = useSelector(
    (state) => state.paginator.pages.employeePayroll.first
  );
  const rowsPerPage = useSelector(
    (state) => state.paginator.pages.employeePayroll.rowsPerPage
  );

  const [localFirst, setLocalFirst] = useState(first || 0);
  const [localRowsPerPage, setLocalRowsPerPage] = useState(rowsPerPage || 10);

  const onPageChange = (e) => {
    setLocalFirst(e.first);
    dispatch(setFirst({ page: "employeePayroll", value: e.first }));
  };

  const onPageRowsChange = (event) => {
    setLocalRowsPerPage(event.rows);
    dispatch(setPageRows({ page: "employeePayroll", value: event.rows }));
  };

  const rowsPerPageOptions = [10, 25, 50];

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setLocalRowsPerPage(newRowsPerPage);
    dispatch(setPageRows({ page: "employeePayroll", value: newRowsPerPage }));
  };

  const openModal = () => {
    setIsModalOpen(true);
  };
  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const showMenu = (event) => {
    menuRef.current.toggle(event);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFromDate(null);
    setToDate(null);
  };
  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const months = Array.from({ length: 12 }, (_, index) => {
    const date = new Date(2000, index, 1);
    const monthName = date.toLocaleString("en-US", { month: "long" });
    return { label: monthName, value: monthName };
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(EMPLOYEE_API.ACTIVE_INACTIVE_USERS);
      setPayslips(response.data.usersData);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);



  const fetchPayrollData = async () => {
    try {
      const response = await axios.get(PAYROLL_API.GET_ALL_PAYROLL);
      const payrollDetails = response.data.payrollDetails;

      setPayroll(payrollDetails);

      const uniqueMonths = [
        ...new Set(payrollDetails.map((item) => item.month)),
      ];
      const sortedMonths = uniqueMonths.sort((a, b) => {
        const monthsOrder = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        return monthsOrder.indexOf(a) - monthsOrder.indexOf(b);
      });

      const monthsOrder = [
        { label: "January", value: "January" },
        { label: "February", value: "February" },
        { label: "March", value: "March" },
        { label: "April", value: "April" },
        { label: "May", value: "May" },
        { label: "June", value: "June" },
        { label: "July", value: "July" },
        { label: "August", value: "August" },
        { label: "September", value: "September" },
        { label: "October", value: "October" },
        { label: "November", value: "November" },
        { label: "December", value: "December" }
      ];

      const filterOptions = monthsOrder?.map((month) => ({
        label: month,
        value: month,
      }));

      // Remove options with empty labels
      const filteredOptions = filterOptions?.filter(option => option.label != "");
      setMonthFilterOptions(monthsOrder);

      // Calculate unique years for filter options
      const uniqueYears = [...new Set(payrollDetails.map((item) => item.year))];

      const yearFilterOptions = uniqueYears.map((year) => ({
        label: String(year),
        value: year,
      }));

      const filteredOptionsYear = yearFilterOptions?.filter(option => option.label != "");

      setYearFilterOptions(filteredOptionsYear);

    } catch (error) {
      console.error("Error fetching payroll data:", error);
    }
  };

  useEffect(() => {
    fetchPayrollData();
  }, []);

  const fetchPayHeads = async () => {
    try {
      const response = await axios.get(PAY_HEAD_API.GET_ALL_PAYHEAD);
      const payHeads = response.data;
      const earningOptions = payHeads
        .filter((payHead) => payHead.payhead_type === "earnings")
        .map((payHead) => ({
          label: payHead.payhead_name,
          value: payHead.payhead_id,
          payhead_name: payHead.payhead_name,
        }));
      const deductionOptions = payHeads
        .filter((payHead) => payHead.payhead_type === "deductions")
        .map((payHead) => ({
          label: payHead.payhead_name,
          value: payHead.payhead_id,
          payhead_name: payHead.payhead_name,
        }));
      setEarningOptions(earningOptions);
      setDeductionOptions(deductionOptions);
    } catch (error) {
      console.error("Error fetching pay head data:", error);
    }
  };

  useEffect(() => {
    fetchPayHeads();
  }, []);

  const employeeOptions = payslips.map((employee) => ({
    label: `${employee.firstname} ${employee.lastname}`,
    value: employee,
  }));

  const handleEmployeeChange = (e) => {
    const selectedEmployee = e.value;
    setSelectedEmployee(selectedEmployee);
    setSelectedEmployeeDetails({
      name: `${selectedEmployee.firstname} ${selectedEmployee.lastname}`,
      department: selectedEmployee.department,
      position: selectedEmployee.position,
      empid: selectedEmployee.empid,
      salary: selectedEmployee.salary,
    });
    setAttendanceDetails((prevDetails) => ({
      ...prevDetails,
      empid: selectedEmployee.empid,
    }));
  };

  useEffect(() => {
    setAttendanceDetails((prevDetails) => ({
      ...prevDetails,
      from_date: fromDate,
      to_date: toDate,
    }));
  }, [fromDate, toDate]);

  // Function to fetch attendance data
  const fetchAttendanceData = async () => {
    const { empid, from_date, to_date } = attendanceDetails;
    try {
      const response = await axios.get(
        ATTENDANCE_API.ATTENDANCE_PAYROLL_GET(empid),
        {
          params: {
            fromDate: from_date,
            toDate: to_date,
          },
        }
      );
      setNumSundays(response.data.totalSundays);
      const totalDaysMissing = response.data.totalDaysMissing || 0;
      setTotalDaysPresent(totalDaysMissing);
    } catch (error) {
      console.error("Error fetching attendance data:", error.message);
    }
  };

  // Effect to fetch attendance data when attendanceDetails change
  useEffect(() => {
    if (
      attendanceDetails.empid &&
      attendanceDetails.from_date &&
      attendanceDetails.to_date
    ) {
      fetchAttendanceData();
    }
  }, [attendanceDetails]);

  const totalMissingDays = totalDaysPresent > 0 ? totalDaysPresent : 0;

  useEffect(() => {
    calculateTotalEarnings();
    calculateNetPay();
  }, [earnings]);

  useEffect(() => {
    calculateTotalDeductions();
    calculateNetPay();
  }, [earnings, deductions]);

  useEffect(() => {
    calculateTotalEarnings();
    calculateTotalDeductions();
  }, [earnings, deductions]);

  useEffect(() => {
    calculateNetPay();
  }, [totalEarnings, totalDeductions]);

  const calculateTotalEarnings = () => {
    const total = earnings.reduce(
      (acc, earning) => acc + parseFloat(earning.payhead_amount || 0),
      0
    );
    setTotalEarnings(total);
  };

  const calculateTotalDeductions = () => {
    const total = deductions.reduce(
      (acc, deduction) => acc + parseFloat(deduction.payhead_amount || 0),
      0
    );
    setTotalDeductions(total);
  };

  const calculateNetPay = () => {
    let netPay;
    if (deductions.length == 0) {
      netPay = totalEarnings;
    } else {
      netPay = totalEarnings - totalDeductions;
    }
    setNetPay(netPay);
  };
  const handleDeleteEarning = (index) => {
    const updatedEarnings = [...earnings];
    updatedEarnings.splice(index, 1);
    setEarnings(updatedEarnings);
    calculateTotalEarnings();
    calculateTotalDeductions();
    calculateNetPay();
  };

  const handleDeleteDeduction = (index) => {
    const updatedDeductions = [...deductions];
    updatedDeductions.splice(index, 1);
    setDeductions(updatedDeductions);
    calculateTotalEarnings();
    calculateTotalDeductions();
    calculateNetPay();
  };

  const openAddPayHeadsDialog = () => {
    setDisplayAddPayHeadsDialog(true);
  };

  const formatMonthYear = (dateString) => {
    if (!dateString) return "";
    const options = { year: "numeric", month: "long" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const extractMonthYear = (dateString) => {
    if (!dateString) return { month: "", year: "" };
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    const [month, year] = formattedDate.split(" ");
    return { month, year };
  };
  const addPayHeadsToEmployee = async () => {
    const { from_date: fromDate, to_date: toDate } = attendanceDetails;
    const { month, year } = extractMonthYear(fromDate);
    if (editMode && selectedPayrollData) {
      try {
        const payrollId = selectedPayrollData.payroll_id;
        if (!payrollId) {
          console.error("Payroll ID is undefined");
          return;
        }
        const updatedPayrollData = {
          month,
          year,
          from_date: fromDate,
          to_date: toDate,
          total_earnings: totalEarnings,
          total_deductions: totalDeductions,
          netpay: netPay,
          payroll_earnings: earnings.map((earning) => ({
            ...earning,
            payhead_id: earningIds[earning.payhead_id],
          })),
          payroll_deductions: deductions.map((deduction) => ({
            ...deduction,
            payhead_id: deductionIds[deduction.payhead_id],
          })),
        };
        await axios.put(
          PAYROLL_API.EDIT_PAYROLL(payrollId),
          updatedPayrollData
        );
        closeAddEditPayHeadsDialog();
        fetchPayrollData();
      } catch (error) {
        console.error("Failed to edit payroll data:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: error.response.data.message,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to edit payroll data",
          });
        }
      }
    } else {
      try {
        const payrollData = {
          empid: selectedEmployee.empid,
          month,
          year,
          from_date: fromDate,
          to_date: toDate,
          salary: selectedEmployee.salary,
          total_earnings: totalEarnings,
          total_deductions: totalDeductions,
          netpay: netPay,
          payroll_earnings: earnings.map((earning) => ({
            ...earning,
            payhead_id: earningIds[earning.payhead_name],
          })),
          payroll_deductions: deductions.map((deduction) => ({
            ...deduction,
            payhead_id: deductionIds[deduction.payhead_name],
          })),
        };

        await axios.post(PAYROLL_API.ADD_PAYROLL, payrollData);
        closeAddEditPayHeadsDialog();
        fetchPayrollData();
      } catch (error) {
        console.error("Failed to add payroll data:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: error.response.data.message,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to add payroll data",
          });
        }
      }
    }
  };

  const handleAddEarning = () => {
    if (newEarning.payhead_id && newEarning.payhead_amount.trim() !== "") {
      const selectedEarningOption = earningOptions.find(
        (option) => option.value === newEarning.payhead_id
      );

      if (selectedEarningOption) {
        const earningData = {
          payhead_name: selectedEarningOption.payhead_name,
          payhead_id: newEarning.payhead_id,
          payhead_amount: newEarning.payhead_amount,
        };

        setEarningIds((prevIds) => ({
          ...prevIds,
          [newEarning.payhead_id]: newEarning.payhead_id,
        }));
        setEarnings([...earnings, earningData]);
        setNewEarning({
          payhead_id: "",
          payhead_name: "",
          payhead_amount: "",
        });
      } else {
        console.log("Invalid earning option");
      }
    } else {
      console.log("Invalid earning data");
    }
  };

  const handleAddDeduction = () => {
    if (newDeduction.payhead_id && newDeduction.payhead_amount.trim() !== "") {
      const selectedDeductionOption = deductionOptions.find(
        (option) => option.value === newDeduction.payhead_id
      );

      if (selectedDeductionOption) {
        const deductionData = {
          payhead_name: selectedDeductionOption.payhead_name,
          payhead_id: newDeduction.payhead_id,
          payhead_amount: newDeduction.payhead_amount,
        };

        setDeductionIds((prevIds) => ({
          ...prevIds,
          [newDeduction.payhead_id]: newDeduction.payhead_id,
        }));
        setDeductions([...deductions, deductionData]);
        setNewDeduction({
          payhead_id: "",
          payhead_name: "",
          payhead_amount: "",
        });
      } else {
        console.log("Invalid deduction option");
      }
    } else {
      console.log("Invalid deduction data");
    }
  };

  const openAddEditPayHeadsDialog = (editMode, rowData) => {
    setEditMode(editMode);
    if (rowData.empid !== null) {
      fetchAttendanceData();
    }
    setSelectedPayrollData(rowData);
    setDisplayAddPayHeadsDialog(true);
    if (editMode && rowData) {
      const { empid, from_date, to_date, netPay } = rowData;
      const selectedEmployee = payslips.find(
        (employee) => employee.empid === empid
      );
      setSelectedEmployee(selectedEmployee);
      setSelectedEmployeeDetails({
        name: `${selectedEmployee.firstname} ${selectedEmployee.lastname}`,
        department: selectedEmployee.department,
        empid: selectedEmployee.empid,
        salary: selectedEmployee.salary,
      });
      setFromDate(from_date);
      setToDate(to_date);
      setAttendanceDetails((prevDetails) => ({
        ...prevDetails,
        empid: empid,
      }));
      const getPayheadLabelById = (payheadId, payheadOptions) => {
        const payhead = payheadOptions.find(
          (option) => option.value === payheadId
        );
        return payhead ? payhead.label : "";
      };

      const existingEarnings = rowData.payroll_earnings.map((earning) => ({
        payhead_name: getPayheadLabelById(earning.payhead_id, earningOptions),
        payhead_id: earning.payhead_id,
        payhead_amount: earning.payhead_amount,
      }));
      setEarnings(existingEarnings);
      setEarningIds((prevIds) => ({
        ...prevIds,
        ...existingEarnings.reduce((acc, curr) => {
          acc[curr.payhead_id] = curr.payhead_id;
          return acc;
        }, {}),
      }));

      const existingDeductions = rowData.payroll_deductions.map(
        (deduction) => ({
          payhead_name: getPayheadLabelById(
            deduction.payhead_id,
            deductionOptions
          ),
          payhead_id: deduction.payhead_id,
          payhead_amount: deduction.payhead_amount,
        })
      );
      setDeductions(existingDeductions);
      setDeductionIds((prevIds) => ({
        ...prevIds,
        ...existingDeductions.reduce((acc, curr) => {
          acc[curr.payhead_id] = curr.payhead_id;
          return acc;
        }, {}),
      }));
    }
  };
  const closeAddPayHeadsDialog = () => {
    setNewEarning({
      payhead_id: "",
      payhead_name: "",
      payhead_amount: "",
    });
    setNewDeduction({
      payhead_id: "",
      payhead_name: "",
      payhead_amount: "",
    });
    setEarningIds({});
    setDeductionIds({});
    setTotalEarnings(0);
    setTotalDeductions(0);
    setNetPay(0);
    setEarnings([]);
    setDeductions([]);
    setSelectedEmployee(null);
    setSelectedEmployeeDetails({
      name: "",
      department: "",
      empid: "",
      salary: "",
    });
    setFromDate(null);
    setToDate(null);
    setTotalDaysPresent(0);
    setDisplayAddPayHeadsDialog(false);
    setEditMode(false);
  };

  const closeAddEditPayHeadsDialog = () => {
    setEditMode(false);
    setSelectedPayrollData(null);
    setNewEarning({
      payhead_id: "",
      payhead_name: "",
      payhead_amount: "",
    });
    setNewDeduction({
      payhead_id: "",
      payhead_name: "",
      payhead_amount: "",
    });
    setEarningIds({});
    setDeductionIds({});
    setTotalEarnings(0);
    setTotalDeductions(0);
    setNetPay(0);
    setEarnings([]);
    setDeductions([]);
    setSelectedEmployee(null);
    setSelectedEmployeeDetails({
      name: "",
      department: "",
      empid: "",
      salary: "",
    });
    setTotalDaysPresent(0);
    setNumSundays(0);
    setFromDate(null);
    setToDate(null);
    setDisplayAddPayHeadsDialog(false);
  };

  const handleDeletePayroll = (payrollId) => {
    setSelectedPayrollToDelete(payrollId);
    setDisplayConfirmation(true);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmation(false);
    setSelectedPayrollToDelete(null);
  };

  const confirmDelete = async () => {
    if (selectedPayrollToDelete) {
      try {
        await axios.delete(PAYROLL_API.DELETE_PAYROLL(selectedPayrollToDelete));
        fetchPayrollData();
      } catch (error) {
        console.error("Failed to delete payroll data:", error);
      } finally {
        hideConfirmationModal();
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (!selectedEmployee) {
      isValid = false;
    }
    if (earnings.length === 0) {
      isValid = false;
    }
    if (deductions.length === 0) {
      isValid = false;
    }
    return isValid;
  };
  const selectedMonthPaySlip = useSelector(
    (state) => state.paginator.selectedMonthEmp
  );

  const selectedYearPaySlip = useSelector(
    (state) => state.paginator.selectedYearEmp
  );

  useEffect(() => {
    setSearchMonth(selectedMonthPaySlip || "");
    setSearchYear(selectedYearPaySlip || "");
  }, [selectedYearPaySlip, selectedMonthPaySlip]);


  useEffect(() => {
    handleSearch();
  }, [payroll]);

  const filterPayrollData = (payrollData, searchYear, searchMonth) => {
    return payrollData.filter((row) => {
      const isMonthMatch = row.month
        .toString()
        .toLowerCase()
        .includes(searchMonth.toLowerCase());

      const isYearMatch = row.year
        .toString()
        .toLowerCase()
        .includes(searchYear.toLowerCase());

      return isMonthMatch && isYearMatch;
    });
  };

  const handleSearch = () => {
    if (searchMonth != "" || searchYear != "") {
      dispatch(selectMonthEmp(searchMonth));
      dispatch(selectYearEmp(searchYear));
    }
    if (searchMonth === "" && searchYear === "") {
      setFilteredPayroll(payroll);
      setTotalNetPay(0);
      setTotalEarnings(0);
      setTotalDeductions(0);
    } else {
      const filteredData = filterPayrollData(payroll, searchYear, searchMonth);

      const filteredTotalNetPay = filteredData.reduce(
        (acc, item) => acc + (item.netpay || 0),
        0
      );

      const filteredTotalEarnings = filteredData.reduce(
        (acc, item) => acc + (item.total_earnings || 0),
        0
      );

      const filteredTotalDeductions = filteredData.reduce(
        (acc, item) => acc + (item.total_deductions || 0),
        0
      );

      setFilteredPayroll(filteredData);
      setTotalNetPay(filteredTotalNetPay);
      setTotalEarnings(filteredTotalEarnings);
      setTotalDeductions(filteredTotalDeductions);
    }
  };

  const clearSearch = () => {
    setSearchMonth("");
    setSearchYear("");
    setTotalNetPay(0);
    setTotalEarnings(0);
    setTotalDeductions(0);
    setFilteredPayroll(payroll);
    dispatch(selectMonthEmp(""));
    dispatch(selectYearEmp(""));
    setLocalFirst(0);
    setLocalRowsPerPage(5);
    dispatch(clearStore());
  };

  const downloadAsExcel = () => {
    const fieldsToInclude = [
      "payroll_id",
      "empid",
      "salary",
      "month",
      "year",
      "total_earnings",
      "total_deductions",
      "netpay",
    ];

    const filteredPayrolls = filteredPayroll.map((item) => {
      const payslip = payslips.find((p) => p.empid === item.empid);
      const filteredItem = {};
      fieldsToInclude.forEach((field) => {
        filteredItem[field] = item[field];
      });
      filteredItem["name"] = payslip
        ? `${payslip.firstname} ${payslip.lastname}`
        : "";
      filteredItem["bank_name"] = payslip ? payslip.bank_name : "";
      filteredItem["account_number"] = payslip ? payslip.account_number : "";
      filteredItem["IFSC_NO"] = payslip ? payslip.IFSC_Code : "";
      return filteredItem;
    });

    const renamedPayrolls = filteredPayrolls.map((item) => {
      return {
        "Payroll Id": item.payroll_id,
        "Employee Id": item.empid,
        Name: item.name,
        Salary: item.salary,
        Month: item.month,
        Year: item.year,
        "Total Earnings": item.total_earnings,
        "Total Deductions": item.total_deductions,
        "Net Pay": item.netpay,
        "Bank Name": item.bank_name,
        "IFSC No": item.IFSC_NO,
        "Account Number": item.account_number,
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(renamedPayrolls);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Payroll");
    XLSX.writeFile(workbook, "payroll_report.xlsx");
  };

  const downloadAsPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        [
          "Payroll Id",
          "Employee ID",
          "Name",
          "Salary",
          "Month",
          "Year",
          "Total Earnings",
          "Total Deductions",
          "Net Pay",
        ],
      ],
      body: filteredPayroll.map((item) => {
        const payslip = payslips.find((p) => p.empid === item.empid);
        const name = payslip ? `${payslip.firstname} ${payslip.lastname}` : "";
        return [
          item.payroll_id,
          item.empid,
          name,
          item.salary,
          item.month,
          item.year,
          item.total_earnings,
          item.total_deductions,
          item.netpay,
        ];
      }),
    });
    doc.save("payroll.pdf");
  };

  const items = [
    {
      label: "Download as",
      items: [
        {
          label: "PDF",
          icon: <FaFilePdf style={{ color: "red", marginRight: "4px" }} />,
          command: () => downloadAsPDF(),
        },
        {
          label: "Excel",
          icon: <FaFileExcel style={{ color: "green", marginRight: "4px" }} />,
          command: () => downloadAsExcel(),
        },
      ],
    },
  ];

  return (
    <div>
      <div className="md:flex block justify-content-between">
        <h2 className="my-2">Employees Payroll</h2>
        <Toast ref={toast} />
        <div className="md:col-4 lg:col-3 col-12 flex justify-content-end">
          <div className="p-inputgroup">
            <InputText
              type="text"
              className="search-input w-full p-inputtext p-component"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}

            />
            <Button icon="pi pi-search" className="search-icon" />
          </div>
        </div>
      </div>
      <div>
        <div className="grid align-items-center gap-1 md:gap-2 lg:gap-0 my-2">
          <div className="lg:col-2 md:col-4 sm:col-4 col-12">
            <Dropdown
              placeholder="Select Month"
              value={searchMonth}
              options={monthFilterOptions?.map(option => ({
                label: <span><span>{option.label}</span></span>,
                value: option.value
              }))}
              onChange={(e) => setSearchMonth(e.value)}
              className="w-full"
            />
          </div>
          <div className="lg:col-2 md:col-4 sm:col-4 col-12">
            <Dropdown
              placeholder="Select Year"
              value={searchYear}
              options={yearFilterOptions?.map(option => ({
                label: <span><span>{option.label}</span></span>,
                value: option.value
              }))}
              onChange={(e) => setSearchYear(e.value)}
              className="w-full"
            />
          </div>
          <div className="lg:col-2 md:col-3 sm:col-3  col-5 ">
            <Button
              label="Search"
              className=""
              style={{ height: "38px" }}
              onClick={handleSearch}
            />
          </div>
          <div className="lg:col-2 md:col-4  sm:col-4 col-5">
            <Button
              icon="fi fi-bs-clear-alt"
              label="Clear"
              className=""
              style={{ height: "38px" }}
              onClick={clearSearch}
            />
          </div>
          <div className="lg:col-3 md:col-4 sm:col-4 col-12">
            <Button
              style={{ height: "38px", marginLeft: "4px" }}
              onClick={openModal}
            >
              Add Monthly Payroll
            </Button>
          </div>
          <AllPayRoll
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            fetchPayrollData={fetchPayrollData}
          />
          <div className="lg:col-1 md:col-1 sm:col-1 col-12">
            <button
              style={{
                color: "#4075B6",
                fontSize: "26px",
                background: "none",
                border: "none",
              }}
              onClick={showMenu}
            >
              <FaDownload />
            </button>
            <Menu model={items} popup ref={menuRef} />
          </div>

        </div>
        <div className="flex justify-content-end my-1 ">
          <div className="text-right">
            <i
              class="fi fi-sr-square-plus"
              onClick={openAddPayHeadsDialog}
              style={{
                color: "#6366F1",
                fontSize: "40px",
                marginRight: "9px",
              }}
            ></i>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="sm:col-3 col-12">
          <div className="card flex justify-content-between flex-row">
            <div className="">
              <p style={{ fontSize: "30px", fontWeight: "600" }}>
                {totalEarnings.toLocaleString("en-US")}
                {/* {totalNetPay.toLocaleString("en-US")} */}
              </p>
              <h5 style={{ fontSize: "1rem", fontWeight: "500" }}>
                Total Monthly Salary
              </h5>
            </div>
            <div
              className="img-permi"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                fontSize: "2rem",
                background: "#fff3e1  ",
                padding: "7px 20px",
                borderRadius: "10px",
                color: "#F79807",
              }}
            >
              <i class="fi fi-ss-sack-dollar"></i>
            </div>
          </div>
        </div>
        <div className="sm:col-3 col-12">
          <div className="card flex justify-content-between flex-row">
            <div className="">
              <p style={{ fontSize: "30px", fontWeight: "600" }}>
                {totalDeductions.toLocaleString("en-US")}
              </p>
              <h5 style={{ fontSize: "1rem", fontWeight: "500" }}>
                Deductions Total
              </h5>
            </div>
            <div
              className="img-permi"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                fontSize: "2rem",
                background: "rgb(255 232 235)",
                padding: "7px 20px",
                borderRadius: "10px",
                color: "#E60023",
              }}
            >
              <i class="fi fi-br-arrow-trend-down"></i>
            </div>
          </div>
        </div>
        <div className="sm:col-3 col-12">
          <div className="card flex justify-content-between flex-row">
            <div className="">
              <p style={{ fontSize: "30px", fontWeight: "600" }}>0</p>
              <h5 style={{ fontSize: "1rem", fontWeight: "500" }}>
                Overtime Total
              </h5>
            </div>
            <div
              className="img-permi"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                fontSize: "2rem",
                background: "#EFF5FE",
                padding: "7px 20px",
                borderRadius: "10px",
                color: "#1663FE",
              }}
            >
              <i class="fi fi-rs-investment"></i>
            </div>
          </div>
        </div>
        <div className="sm:col-3 col-12">
          <div className="card flex justify-content-between flex-row">
            <div className="">
              <p style={{ fontSize: "30px", fontWeight: "600" }}>
                {/* {totalEarnings.toLocaleString("en-US")} */}
                {totalNetPay.toLocaleString("en-US")}
              </p>
              <h5 style={{ fontSize: "1rem", fontWeight: "500" }}>
                Total Salary Disbursal
              </h5>
            </div>
            <div
              className="img-permi"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                fontSize: "2rem",
                background: "rgb(211 255 252)",
                padding: "7px 20px",
                borderRadius: "10px",
                color: "#01B7AA",
              }}
            >
              <i class="fi fi-br-arrow-trend-up"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="flex justify-content-end">
          <Button
            style={{
              background: "#DC2626",
              border: "#DC2626",
              padding: "8px 13px",
              fontSize: "14px",
              marginBottom: "13px",
              marginRight: "4px",
            }}
            className=""
            onClick={openDeleteModal}
            tooltip="Delete Monthly Payroll"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
            }}
          >
            <i
              class="fi fi-rr-trash"
              style={{ fontSize: "14px", marginRight: "4px" }}
            ></i>
            Delete
          </Button>
          <DeletePayroll
            deleteModalOpen={deleteModalOpen}
            closeDeleteModal={closeDeleteModal}
            fetchPayrollData={fetchPayrollData}
          />
        </div>
        <DataTable
          value={filteredPayroll}
          selectionMode="single"
          selection={selectedPayslips}
          onSelectionChange={(e) => setSelectedPayslips(e.value)}
          dataKey="payroll_id"
          globalFilter={globalFilter.trim() ? globalFilter : null}
          paginator
          removableSort
          className="dataTableHiddenFooterBg"
          rows={localRowsPerPage}
          first={localFirst}
          onPage={onPageChange}
          onPageRows={onPageRowsChange}
          footer={
            <Dropdown
              value={localRowsPerPage}
              options={rowsPerPageOptions.map((option) => ({
                label: option,
                value: option,
              }))}
              onChange={handleRowsPerPageChange}
              placeholder="Select Rows Per Page"
            />
          }
          emptyMessage="No data available"
        >
          <Column
            field="empid"
            header="Emp Id"
            bodyStyle={{ minWidth: "120px" }}
            sortable
          ></Column>
          <Column
            field="from_date"
            header="From Date"
            bodyStyle={{ minWidth: "120px" }}
            sortable
          ></Column>
          <Column
            field="to_date"
            header="To date"
            bodyStyle={{ minWidth: "120px" }}
            sortable
          ></Column>
          <Column
            header="Employee Name"
            body={(rowData) => {
              const employee = payslips.find(
                (emp) => emp.empid === rowData.empid
              );
              const employeeName = employee
                ? `${employee.firstname} ${employee.lastname}`
                : "";
              return employeeName;
            }}
            bodyStyle={{ minWidth: "120px" }}
          ></Column>
          {/* <Column field="month" header="Month"></Column> */}
          {/* <Column field="year" header="Year"></Column> */}
          <Column
            field="salary"
            header="Salary"
            bodyStyle={{ minWidth: "90px" }}
            sortable
          ></Column>
          <Column
            field="overtime"
            header="Overtime"
            body={(rowData) => rowData.overtime || "-"}
          ></Column>
          {/* <Column field="total_earnings" header="Earnings"></Column> */}
          <Column field="total_deductions" header="Deductions"></Column>
          <Column field="netpay" header="Net pay"></Column>
          <Column
            header="Payslip"
            body={(rowData, rowIndex) => (
              <>
                <div className="flex justify-content-start align-items-center">
                  <Link to={`/PaySlip/${rowData.payroll_id}`}>
                    <Button
                      tooltip="View"
                      tooltipOptions={{
                        position: "bottom",
                        mouseTrack: true,
                        mouseTrackTop: 15,
                      }}
                      className="p-button-primary"
                      style={{
                        borderRadius: "50%",
                        padding: "12px 15px",
                        background: "#FD8E2D",
                        border: "#FD8E2D",
                      }}
                    >
                      <i className="fi fi-rr-eye"></i>
                    </Button>
                  </Link>
                </div>
              </>
            )}
          ></Column>
          {/* <Column
            header="Salary certificate"
            body={(rowData, rowIndex) => (
              <div className="flex justify-content-start align-items-center">
                <Link to={`/SalaryCertificate/${rowData.payroll_id}`}>
                  <Button
                    tooltip="SalaryCertificate"
                    tooltipOptions={{
                      position: "bottom",
                      mouseTrack: true,
                      mouseTrackTop: 15,
                    }}
                    className="p-button-primary"
                    style={{ borderRadius: "50%", padding: "12px 15px" }}
                  >
                    <i class="fi fi-rs-diploma"></i>
                  </Button>
                </Link>
              </div>
            )}
            style={{ wordBreak: "break-word", width: "110px" }}
          ></Column> */}
          <Column
            header="Actions"
            body={(rowData) => (
              <div className="flex justify-content-start align-items-center">
                <Button
                  tooltip="Edit"
                  tooltipOptions={{
                    position: "bottom",
                    mouseTrack: true,
                    mouseTrackTop: 15,
                  }}
                  icon="pi pi-pencil"
                  className="p-button-rounded p-mr-2"
                  style={{
                    marginRight: "10px",
                    background: "#1677FF",
                    border: "none",
                  }}
                  onClick={() => openAddEditPayHeadsDialog(true, rowData)}
                />
                <Button
                  tooltip="Delete"
                  tooltipOptions={{
                    position: "bottom",
                    mouseTrack: true,
                    mouseTrackTop: 15,
                  }}
                  icon="pi pi-trash"
                  className="p-button-rounded p-button-danger mr-2"
                  onClick={() => handleDeletePayroll(rowData.payroll_id)}
                />
              </div>
            )}
          ></Column>
        </DataTable>
        <EmployeeAddPayroll
          displayAddPayHeadsDialog={displayAddPayHeadsDialog}
          editMode={editMode}
          closeAddEditPayHeadsDialog={closeAddEditPayHeadsDialog}
          employeeOptions={employeeOptions}
          selectedEmployee={selectedEmployee}
          handleEmployeeChange={handleEmployeeChange}
          selectedEmployeeDetails={selectedEmployeeDetails}
          months={months}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          earnings={earnings}
          handleDeleteEarning={handleDeleteEarning}
          earningOptions={earningOptions}
          newEarning={newEarning}
          setNewEarning={setNewEarning}
          totalEarnings={totalEarnings}
          deductions={deductions}
          handleDeleteDeduction={handleDeleteDeduction}
          deductionOptions={deductionOptions}
          newDeduction={newDeduction}
          setNewDeduction={setNewDeduction}
          totalDeductions={totalDeductions}
          netPay={netPay}
          closeAddPayHeadsDialog={closeAddPayHeadsDialog}
          validateForm={validateForm}
          addPayHeadsToEmployee={addPayHeadsToEmployee}
          handleAddEarning={handleAddEarning}
          handleAddDeduction={handleAddDeduction}
          attendanceDetails={attendanceDetails}
          totalDaysPresent={totalDaysPresent}
          totalMissingDays={totalMissingDays}
          setEarnings={setEarnings}
          setDeductions={setDeductions}
          setSelectedPayrollData={setSelectedPayrollData}
          selectedPayrollData={selectedPayrollData}
          setEarningIds={setEarningIds}
          earningIds={earningIds}
          deductionIds={deductionIds}
          setDeductionIds={setDeductionIds}
        />
        {selectedPayrollToDelete && (
          <EmployeePayrollDelete
            displayConfirmation={displayConfirmation}
            hideConfirmationModal={hideConfirmationModal}
            confirmDelete={confirmDelete}
          />
        )}
      </div>
    </div>
  );
}