
import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import { FaDownload } from "react-icons/fa";
import html2pdf from "html2pdf.js";
import moment from "moment";

import { ATTENDANCE_API, EMPLOYEE_API, HOLIDAYS_API } from "../../constants/api";

export default function AttendanceSheet() {
  const [attendanceData, setAttendanceData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [holidays, setHolidays] = useState([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState(moment().format("YYYY-MM"));
  const holidaysDates = holidays.map((holiday) => holiday.holiday_date);
  const currentDate = new Date().toISOString().split("T")[0];

  useEffect(() => {
    if (selectedMonthYear) {
      const [selectedYear, selectedMonth] = selectedMonthYear.split("-");
      setSelectedMonth(parseInt(selectedMonth, 10));
      setSelectedYear(parseInt(selectedYear, 10));
    }
  }, [selectedMonthYear]);

  useEffect(() => {
    fetchAttendanceData();
  }, []);

  useEffect(() => {
    getAllHolidays();
  }, []);

  useEffect(() => {
    fetchEmployeeData();
  }, []);

  const fetchAttendanceData = () => {
    axios
      .get(ATTENDANCE_API.ATTENDANCE_TODAY)
      .then((response) => {
        setAttendanceData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error.message);
      });
  };

  const getAllHolidays = async () => {
    try {
      const response = await axios.get(HOLIDAYS_API.HOLIDAYS_ALL);
      setHolidays(response.data);
    } catch (error) {
      console.error("Error fetching holidays:", error);
    }
  };

  const fetchEmployeeData = () => {
    axios
      .get(EMPLOYEE_API.GET_ALL)
      .then((response) => {
        setEmployeeData(response.data.usersData);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error.message);
      });
  };

  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  const columns = [];
  const daysInMonth = getDaysInMonth(selectedMonth, selectedYear);

  for (let i = 1; i <= daysInMonth; i++) {
    columns.push(
      <Column
        key={i}
        field={`day${i}`}
        header={i.toString()}
        style={{ minWidth: "30px", fontSize: "12px", padding: "0" }}
      />
    );
  }

  const countSundays = (year, month) => {
    let count = 0;
    const daysInMonth = new Date(year, month, 0).getDate();
    for (let i = 1; i <= daysInMonth; i++) {
      if (new Date(year, month - 1, i).getDay() === 0) {
        count++;
      }
    }
    return count;
  };

  const sundaysCount = countSundays(selectedYear, selectedMonth);

  const downloadPDF = () => {
    const element = document.getElementById("attendance-sheet");

    const opt = {
      margin: 0.5,
      filename: "attendance_sheet.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: [14, 8], orientation: "landscape" },
    };

    html2pdf().from(element).set(opt).save();
  };

  return (
    <div>
      <div className="col-12 md:flex block justify-content-between w-auto align-items-center">
        <div className="md:flex block align-items-center">
          <div>
            <h2 className="my-2">Attendance Sheet</h2>
          </div>
          <div className="sm:ml-4">
            <span className="mr-2">
              ✔️<span className="ml-2">Present</span>
            </span>
            <span className="mr-2">
              ❌<span className="ml-2">Absent</span>
            </span>
            <span className="mr-2">
              🌟<span className="ml-2">Holiday</span>
            </span>
            <span className="mr-2">
              ⭐<span className="ml-2">Sunday</span>
            </span>
          </div>
        </div>
        <div className=" md:flex justify-content-end mt-3 sm:mt-0">
          <div className="p-inputgroup">
            <button
              style={{
                color: "#4075B6",
                fontSize: "26px",
                background: "none",
                border: "none",
                marginRight: "25px",
              }}
              onClick={downloadPDF}
            >
              <FaDownload />
            </button>
            <input
              id="monthPicker"
              type="month"
              value={selectedMonthYear}
              onChange={(e) => setSelectedMonthYear(e.target.value)}
              style={{
                boxShadow: "1px 1px 4px gray",
                outline: "none",
                WebkitAppearance: "none",
                MozAppearance: "none",
                appearance: "none",
                minWidth: '150px',
                maxWidth: '200px'
              }}
              showButtonBar={false}
            />
          </div>
        </div>
      </div>
      {attendanceData.length > 0 && (
        <div id="attendance-sheet">
          <DataTable value={employeeData}>
            <Column
              field="name"
              header="Name"
              body={(rowData) => rowData.firstname + " " + rowData.lastname}
              style={{ width: "90px" }}
              frozen
            />
            {selectedMonth === new Date().getMonth() + 1 && selectedYear === new Date().getFullYear() && (
              <Column
                field="status"
                header="Status"
                body={(rowData) => {
                  const currentMonthAttendanceData = attendanceData.filter(
                    (record) =>
                      moment(record.date).format("MM-YYYY") ===
                      moment().format("MM-YYYY")
                  );

                  const attendanceRecord = currentMonthAttendanceData.find(
                    (record) =>
                      record.userid === rowData.empid &&
                      record.date === currentDate
                  );

                  if (
                    attendanceRecord &&
                    attendanceRecord.status === "Present"
                  ) {
                    return "✔️";
                  } else {
                    return "Absent";
                  }
                }}
                style={{ width: "90px" }}
                frozen
              />
            )}
            {columns.map((column, index) => (
              <Column
                key={index}
                field={`day${index + 1}`}
                header={column.props.header}
                body={(rowData) => {
                  const dayData = rowData[`day${index + 1}`];
                  const date = new Date(
                    selectedYear,
                    selectedMonth - 1,
                    index + 2
                  )
                    .toISOString()
                    .split("T")[0];
                  const currentDate = new Date().toISOString().split("T")[0];
                  const isFutureDate = date > currentDate;
                  const isFutureSunday =
                    new Date(
                      selectedYear,
                      selectedMonth - 1,
                      index + 1
                    ).getDay() === 0 && isFutureDate;

                  if (isFutureDate) {
                    if (holidaysDates.includes(date)) {
                      return "🌟";
                    } else if (isFutureSunday) {
                      return "⭐";
                    } else {
                      return "-";
                    }
                  } else {
                    const attendanceRecords = attendanceData.filter(
                      (record) => record.userid === rowData.empid
                    );
                    const attendanceRecord = attendanceRecords.find(
                      (record) => record.date === date
                    );

                    if (
                      attendanceRecord &&
                      attendanceRecord.status === "Present"
                    ) {
                      return "✔️";
                    }
                    else if (
                      attendanceRecord &&
                      attendanceRecord.status === "Optional Leave"
                    ) {
                      return "🧧";
                    } else if (
                      attendanceRecord &&
                      attendanceRecord.status !== "Present"
                    ) {
                      return "❌";
                    } else if (
                      new Date(
                        selectedYear,
                        selectedMonth - 1,
                        index + 1
                      ).getDay() === 0
                    ) {
                      return "⭐";
                    } else if (holidaysDates.includes(date)) {
                      return "🌟";
                    } else {
                      return "❌";
                    }
                  }
                }}
                style={{
                  minWidth: "30px",
                  fontSize: "12px",
                  padding: "0",
                  textAlign: "center",
                }}
              />
            ))}
          </DataTable>
        </div>
      )}
    </div>
  );
}

