import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { TabMenu } from "primereact/tabmenu";
import { TASK_API, TASK_QUERY_API, EMPLOYEE_API } from "../../../constants/api";
import TaskFile from "../TaskFileDetails";
const TaskUserHistory = () => {
  const navigate = useNavigate();
  const { taskId } = useParams();
  const [taskDetails, setTaskHistory] = useState([]);
  const [taskQuery, setTaskQuery] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [visible, setVisible] = useState(false);
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState("");
  const [allEmployees, setAllEmployees] = useState([]);
  const [allTasks, setAllTasks] = useState([]);

  const statusOptions = [
    { name: "Returned", value: "Returned" },
    { name: "ReSolved", value: "ReSolved" },
  ];

  const tableHeaderStyle = {
    border: "1px solid black",
    backgroundColor: "#f2f2f2",
    padding: "8px",
    textAlign: "left",
  };

  const items = [
    { label: "Task", icon: "pi pi-fw pi-calendar" },
    { label: "Document", icon: "fi fi-rr-document" },
  ];

  const [activeItem, setActiveItem] = useState(items[0]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(EMPLOYEE_API.All_USERS);
        if (response.status === 200) {
          const employeesData = response.data.usersData;
          setAllEmployees(employeesData);
        }
      } catch (error) {
        console.error("Error fetching employees:", error.message);
      }
    };

    fetchEmployees();
  }, []);

  const handleSubmit = async () => {
    try {
      await axios.post(TASK_QUERY_API.TASK_QUERY_ADD, {
        task_id: taskId,
        user_id: selectedUser,
        query_comment: comment,
        query_status: status,
      });
      setVisible(false);
      fetchTaskQueryDetails();
      fetchTaskDetails();
    } catch (error) {
      console.error("Error adding query:", error);
    }
  };
  const handleTabChange = (e) => {
    setActiveItem(e.value);
  };
  const fetchTaskDetails = async () => {
    try {
      const response = await axios.get(TASK_API.GET_PARTICULAR_TASK(taskId));
      setTaskHistory(response.data);
    } catch (error) {
      console.error("Error fetching task details:", error);
    }
  };

  useEffect(() => {
    fetchTaskDetails();
  }, [taskId]);

  const fetchTaskQueryDetails = async () => {
    try {
      const response = await axios.get(TASK_QUERY_API.GET_TASKQUERY(taskId));
      setTaskQuery(response.data);
    } catch (error) {
      console.error("Error fetching task details:", error);
    }
  };

  useEffect(() => {
    fetchTaskQueryDetails();
  }, [taskId]);

  const [isDetailsEntered, setIsDetailsEntered] = useState(false);

  useEffect(() => {
    setIsDetailsEntered(selectedUser && comment && status);
  }, [selectedUser, comment, status]);

  const fetchData = async () => {
    try {
      const userString = localStorage.getItem("user");
      const user = JSON.parse(userString);
      if (!user || !user.data || !user.data.id) {
        console.error(
          "User object is null, undefined, or does not contain an id property."
        );
        return;
      }
      const response = await axios.get(TASK_API.GET_CREATER_TASK(user.data.id));
      setAllTasks(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePrev = () => {
    const currentIndex = allTasks.findIndex(
      (task) => String(task.Task_id) === String(taskId)
    );
    if (currentIndex > 0) {
      const prevTaskId = allTasks[currentIndex - 1].Task_id;
      navigate(`/Taskhistory/${prevTaskId}`);
    }
  };

  const handleNext = () => {
    const currentIndex = allTasks.findIndex(
      (task) => String(task.Task_id) === String(taskId)
    );
    if (currentIndex < allTasks.length - 1) {
      const nextTaskId = allTasks[currentIndex + 1].Task_id;
      navigate(`/Taskhistory/${nextTaskId}`);
    }
  };

  return (
    <div>
      <div className="flex justify-content-between mt-3">
        <div className="mb-4">
          <i
            className="pi pi-arrow-left"
            style={{ fontSize: "2rem", cursor: "pointer" }}
            onClick={() => navigate("/Task")}
          ></i>
        </div>
        <div className="mb-4">
          <Button
            style={{
              fontSize: "14px",
              cursor: "pointer",
              borderRadius: "16px",
              marginRight: "8px",
              padding: "8px",
              textAlign: "center",
            }}
            onClick={handlePrev}
          >
            <i
              class="fi fi-br-angle-double-small-left"
              style={{
                marginRight: "3px",
                marginTop: "2px",
                textAlign: "center",
              }}
            >
              {" "}
            </i>
            Prev
          </Button>
          <Button
            style={{
              fontSize: "14px",
              cursor: "pointer",
              borderRadius: "16px",
              padding: "8px",
              textAlign: "center",
            }}
            onClick={handleNext}
          >
            Next{" "}
            <i
              class="fi fi-br-angle-double-small-right"
              style={{ marginTop: "4px", textAlign: "center" }}
            ></i>
          </Button>
        </div>
      </div>
      <div className="card">
        <TabMenu
          model={items}
          activeItem={activeItem}
          onTabChange={handleTabChange}
          className="custom-tabmenu"
        />

        {activeItem.label === "Task" && (
          <div className="flex justify-content-between">
            <div>
              <h3 className="Text-center mt-4 mb-2">Assigned Task</h3>
            </div>
            <div className="flex justify-content-end">
              <i
                className="fi fi-sr-square-plus"
                onClick={() => setVisible(true)}
                style={{
                  color: "#6366F1",
                  fontSize: "40px",
                  marginRight: "9px",
                }}
              ></i>
            </div>
          </div>
        )}
        {activeItem.label === "Task" && (
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>Task Id</th>
                <th style={tableHeaderStyle}>Remark</th>
                <th style={tableHeaderStyle}>Task From</th>
                <th style={tableHeaderStyle}>Task To</th>
                <th style={tableHeaderStyle}>Task Status</th>
                <th style={tableHeaderStyle}>Task Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "35px",
                  }}
                >
                  {taskDetails.Task_id}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "400px",
                  }}
                >
                  {taskDetails.Details}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "70px",
                  }}
                >
                  {taskDetails.Creater}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "100px",
                  }}
                >
                  {taskDetails.Processer}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "50px",
                  }}
                >
                  {taskDetails.Status}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "80px",
                  }}
                >
                  {taskDetails.StartDate
                    ? `${taskDetails.StartDate.split("-")[2]}-${
                        taskDetails.StartDate.split("-")[1]
                      }-${taskDetails.StartDate.split("-")[0]}`
                    : ""}
                </td>
              </tr>
              {taskQuery.map((query) => (
                <tr key={query.query_id}>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      width: "35px",
                    }}
                  >
                    {query.task_id}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      width: "400px",
                    }}
                  >
                    {query.query_comment}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      width: "70px",
                    }}
                  >
                    {query.user_id}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      width: "100px",
                    }}
                  >
                    {query.Creater}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      width: "50px",
                    }}
                  >
                    {query.query_status}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      width: "80px",
                    }}
                  >
                    {query.query_date
                      ? `${query.query_date.split("-")[2]}-${
                          query.query_date.split("-")[1]
                        }-${query.query_date.split("-")[0]}`
                      : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {activeItem.label === "Document" && <TaskFile />}
        <Dialog
          visible={visible}
          style={{ width: "25vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          onHide={() => setVisible(false)}
          showHeader={false}
          className="taskdetail"
        >
          <h3 style={{ textAlign: "center" }}>Query Response</h3>
          <div className="grid mt-2">
            <div className="col-12">
              <div className="col-12">
                <label htmlFor="EmpId" className="mt-0">
                  Response To
                </label>
                {taskDetails.Processer && (
                  <Dropdown
                    value={selectedUser}
                    onChange={(e) => setSelectedUser(e.value)}
                    options={taskDetails.Processer.split(",")
                      .map((processorId) => {
                        const processor = allEmployees.find(
                          (emp) => emp.id === processorId
                        );
                        if (processor) {
                          return {
                            name: `${processor.firstname} ${processor.lastname}`,
                            value: processor.id,
                          };
                        } else {
                          return null;
                        }
                      })
                      .filter((option) => option !== null)}
                    optionLabel="name"
                    placeholder="Select Person"
                    className="w-full"
                    filter
                    showClear
                  />
                )}
              </div>
            </div>
            <div className="col-12">
              <label htmlFor="EmpId">Comment</label>
              <InputTextarea
                id="Emp Id"
                name="Emp Id"
                className=""
                placeholder="Comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
            <div className="col-12">
              <label htmlFor="EmpId" className="mt-0">
                Status
              </label>
              <Dropdown
                value={status}
                onChange={(e) => setStatus(e.value)}
                options={statusOptions}
                optionLabel="name"
                placeholder="Status"
                className="w-full "
              />
            </div>
          </div>
          <div className="text-center mt-2 flex justify-content-end">
            <Button
              className="p-button-danger"
              label="Cancel"
              icon="pi pi-times"
              style={{ marginRight: "8px" }}
              onClick={() => setVisible(false)}
            />
            <Button
              label="Send"
              icon="pi pi-check"
              onClick={handleSubmit}
              disabled={!isDetailsEntered}
            />
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default TaskUserHistory;